import { useEffect } from 'react';
import AuthService from '@/components/authorization/AuthorizeService';

const useScript = (url) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.id = 'serviceNow';
        script.async = true;
        script.setAttribute('data-serviceNowId', process.env.REACT_APP_SERVICE_NOW_ID ?? '');
        AuthService.getIdToken().then((res) => script.setAttribute('data-accessToken', res ?? ''));

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

export default useScript;
