import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { AuthData, LoginMenu } from 'src/components/authorization/LoginMenu';
import { Icon } from 'src/components/icon-generator';
import { IconType } from 'src/components/icon-generator/dto';
import { useLocation } from 'react-router-dom';
import useScript from 'src/hooks/use-script';
import { FMHLink } from 'src/atoms/fmh-link';
import authService from 'src/components/authorization/AuthorizeService';
import { FCSearchSection } from '../fc/fc-search-section';
import FFMenu from './fc-header-menu/fc-header-menu';
import styles from './fc-header.module.scss';
import FCHeaderProps from './dto';

const serviceNowLogin = () => {
    document.getElementById('serviceNow')?.addEventListener('load', () => {
        if (window.serviceNowOnLogin !== undefined) {
            window.serviceNowOnLogin();
        }
    });
};

const FCHeader: FC<FCHeaderProps> = (props: FCHeaderProps): JSX.Element => {
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [authData, setAuthData] = useState<AuthData>();
    const location = useLocation();

    useScript('/vendor/serviceNow/engagement-messenger-script.js');

    useEffect(() => {
        setIsSearchActive(false);
    }, [location.pathname, location.search]);

    useEffect(() => {
        (async () => {
            const [isAuthenticated, user, impersonationTrigram] = await Promise.all([
                authService.isAuthenticated(),
                authService.getUser(),
                authService.getImpersonationTrigram(),
            ]);

            if (authData?.isAuthenticated !== isAuthenticated) {
                let userName = '';
                if (user) {
                    userName = `${user.name}`;
                    if (impersonationTrigram) userName += ` (as ${impersonationTrigram})`;
                }

                setAuthData({
                    userName,
                    isAuthenticated,
                });
            }
        })();
    }, [authData?.isAuthenticated]);

    useEffect(() => {
        if (authData?.isAuthenticated) {
            serviceNowLogin();
        }
    }, [authData?.isAuthenticated]);

    return (
        <header className={classNames(styles.header, props.classNames)}>
            <nav className={classNames(styles.mainNav, 'container')}>
                <FMHLink
                    href={props.homePageUri}
                    className={classNames(styles.link, 'order-2 order-lg-1 d-flex align-items-center')}
                    onClick={() => props.closeSideMenu()}
                >
                    <Icon icon={IconType.FMHLogo} />
                </FMHLink>

                <div className={classNames(styles.searchWrapper, 'd-flex order-1 order-lg-2 align-items-center')}>
                    {props.search && (
                        <div
                            className={classNames(styles.searchIcon, isSearchActive && styles.activeSearchIcon)}
                            onClick={() => {
                                props.closeSideMenu();
                                setIsSearchActive((value) => !value);
                            }}
                        >
                            <Icon icon="search" />
                        </div>
                    )}

                    <div className={classNames(styles.user, 'd-none d-lg-flex order-3')}>
                        {navigator.onLine && (
                            <LoginMenu
                                authData={authData}
                                loginButtonClassname={styles.text}
                                logInHoldingScreenLink={props.logInHoldingScreenLink}
                            />
                        )}
                    </div>
                </div>

                <div className={classNames(styles.user, 'd-flex d-lg-none order-3')}>
                    {navigator.onLine &&
                        (authData?.isAuthenticated ? (
                            <button className={styles.profileIcon} onClick={props.handleMobileProfileClick}>
                                <Icon icon={IconType.User} />
                            </button>
                        ) : (
                            <FMHLink
                                className={classNames(styles.text, 'd-inline d-lg-none')}
                                href={props.logInHoldingScreenLink.uri}
                            >
                                {props.logInHoldingScreenLink.text}
                            </FMHLink>
                        ))}
                </div>
            </nav>

            <FFMenu toggledClassName={styles.topMenu} firstLvlMenu={props.firstLvlMenu} />

            {props.search && (
                <FCSearchSection
                    searchBarProps={{
                        ...props.search,
                        clicked: isSearchActive,
                    }}
                    isSearchActive={isSearchActive}
                    setSearchActive={setIsSearchActive}
                />
            )}
        </header>
    );
};

export default FCHeader;
