import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { FirstLevelMenu } from 'src/organisms/fmh-header/dto';
import { HeadlessPageConfiguration } from '@/types/apiDtos';
import { FMHMediaScreenHeader } from '@/organisms/fmh-headless-screens-common';
import headlessStyles from '@/organisms/fmh-headless-screens-common/fmh-media-screen-header.module.scss';

interface HeadlessPageContextType {
    configuration?: HeadlessPageConfiguration | undefined;
    setConfiguration: React.Dispatch<React.SetStateAction<HeadlessPageConfiguration>>;
}
const HeadlessPageContext: React.Context<HeadlessPageContextType> = createContext({} as HeadlessPageContextType);

export const HeadlessPageContextProvider = ({ children }: { menu?: Array<FirstLevelMenu>; children: ReactNode }) => {
    const [configuration, setConfiguration] = useState<HeadlessPageConfiguration>({
        displayClock: false,
        logo: undefined,
        clockTimezone: undefined,
        backgroundImage: undefined,
    });

    // Using React Memo, to ensure that the DetailsProvider only update when the overlay state changes. Otherwise the whole tree under will re-render children at every state and value change
    const details = useMemo(
        () => ({
            configuration,
            setConfiguration,
        }),
        [configuration],
    );

    return (
        <HeadlessPageContext.Provider value={details}>
            <div className={headlessStyles.headlessContainer}>
                <FMHMediaScreenHeader
                    displayClock={configuration?.displayClock}
                    logo={configuration?.logo}
                    timezone={configuration?.clockTimezone}
                />

                <div
                    className={`${headlessStyles.bodyBackgroundContainer} ff-bg-blue-cinema`}
                    style={{ backgroundImage: `url(${configuration?.backgroundImage?.src})` }}
                >
                    {children}
                </div>
            </div>
        </HeadlessPageContext.Provider>
    );
};

const useHeadlessPageDetails = () => {
    return useContext(HeadlessPageContext);
};
export default useHeadlessPageDetails;
